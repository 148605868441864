import React from 'react'
import { Button } from './button'
import { Section } from './section'

const Section6 = ({ theme = 'dark' }) => (
  <Section theme={theme} legacy>
    <div id="om-oss" className="row">
      <div className="col col-md-6">
        <h4>KONTAKTA OSS</h4>
        <h1>Digital, tillgänglig och rättvis hälsovård</h1>
        <p>
          Healfy är utvecklad av Nordic Health Innovation (NHI). NHI är ett
          svenskt innovationsbolag som arbetar för att revolutionera hälsovården
          genom att göra den digital, tillgänglig och rättvis. Vi ser en framtid
          där e-hälsa spelar en allt större roll i samhället och där tekniken
          möjliggör för människor att ta ett större ansvar för sin hälsa.
        </p>
      </div>
    </div>
    <div className="row contacts">
      <div className="contact col col-md-4">
        <p className="contact__heading">Jonas Berggren</p>
        <p>CEO & grundare</p>
        <div className="contact__links">
          <a href="mailto:jonas.berggren@nhiab.com">jonas.berggren@nhiab.com</a>
          <a href="tel:+46703089977">+46 70 30 89 977</a>
        </div>
      </div>
      <div className="contact col col-md-4">
        <p className="contact__heading">Björn Sundqvist</p>
        <p>Affärsutvecklare och partner</p>
        <div className="contact__links">
          <a href="mailto:bjorn.sundqvist@nhiab.com">
            bjorn.sundqvist@nhiab.com
          </a>
          <a href="tel:+460733415331">+46 07 33 41 53 31</a>
        </div>
      </div>
      <div className="contact col col-md-4">
        <p className="contact__heading">Besöksadress</p>
        <p>Storgatan 20, Sundsvall</p>
        <p>Drottninggatan 94, Stockholm</p>
      </div>
    </div>
  </Section>
)

export default Section6
