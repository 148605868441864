import React from 'react'
import { Button } from '../components/button'
import tabletImage from '../images/tablet.png'
import { Section } from '../components/section'
import pulseImg from '../images/healfy-pulse.png'

const Section7 = ({ theme = 'light' }) => (
  <Section theme={theme} className="pt-5" legacy>
    <div className="row">
      <h1 className="heading-highligh">Trygg digital hälsoundersökning</h1>
      <div className="col col-md-6">
        <p>
          Healfy kan erbjudas i tre versioner: <span>Hälsorummet</span>,
          <span> Företagshälsovård </span>
          och <span> Utvecklingsregioner</span>.
        </p>
        <p>
          Versionerna bygger på samma tekniska plattform, en Android applikation
          anpassad för läsplattor som trådlöst kopplas upp till olika
          mätenheter.
        </p>
        <p>
          Data och logik ligger på en molnlösning och vårdgivarna använder en
          webbapplikation som kan köras på en modern webbläsare. Grundläggande
          funktionalitet och värde är samma i alla olika versioner.
        </p>
      </div>
      <div className="col col-md-6">
        <ul className="check-list">
          <li>
            Healfy samlar in hälsodata digitalt vilket ökar både tillgänglighet
            och effektivitet
          </li>
          <li>
            Healfy bygger på att datan ska vara lättillgänglig och pedagogisk så
            att användarna både ska vilja och kunna engagera sig i sin egen
            hälsa över tid.
          </li>
          <li>
            Healfy erbjuds som en abonnemangstjänst med en fast grundavgift per
            månad samt en rörlig avgift som baseras på hur mycket tjänsten
            används. Filosofin är enkel när det kommer till betalning – du
            betalar bara när Healfy skapar värde. Inte annars.
          </li>
          <li>
            Healfy är en öppen plattform som välkomnar samarbeten med andra
            aktörer inom healthtech som också vill skapa ett långsiktigt värde
            för människor och samhällen.
          </li>
        </ul>
      </div>
    </div>
  </Section>
)

export default Section7
