import React from 'react'
import classNames from 'classnames'

export const Section = ({ theme, legacy, className, children }) => {
  const cls = classNames(theme, className)

  const renderHeading = (heading, headingHighlight) => {
    const cls = classNames('heading-highligh', headingHighlight)
    return <h1 className="{cls}">{heading}</h1>
  }

  const renderKicker = kicker => (kicker ? <h4>{kicker}</h4> : null)
  const renderBody = body => <p>{body}</p>

  const renderButton = (buttonUrl, buttonText) => {
    return buttonUrl && buttonText ? (
      <a className="page-link" href="{buttonUrl}">
        {buttonText}
      </a>
    ) : null
  }

  if (legacy) {
    return (
      <section className={cls}>
        <div className="container">{children}</div>
      </section>
    )
  } else {
    return (
      <section className={theme}>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {renderKicker('DIGITAL HÄLSOKONTROLL')}
              {renderHeading('Framtidens digitala hälsovård', false)}
              {renderBody(
                'Enkelt. Smart. Nära. Med tjänsten Healfy kan människor själv ta pulsen på sitt välmående. på en plats som fungerar för dom.'
              )}
              {renderButton('Hur funkar det?', '#hur-funkar-det')}
            </div>
          </div>
        </div>
      </section>
    )
  }
}
