import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Section } from '../components/section'
import pulseImg from '../images/healfy-pulse.png'
import tabletImage from '../images/tablet-new.png'

const Section1 = ({ theme = 'light' }) => (
  <Section theme={theme} legacy>
    <div className="row">
      <div className="col-xs-12 col-md-6">
        <div className="kicker">
          <h4>DIGITAL HÄLSOKONTROLL</h4>
          <div className="icon-pulse">
            <img src={pulseImg} alt="healfy-pulse" />
          </div>
        </div>
        <h1>Framtidens digitala hälsovård</h1>
        <p>Enkelt. Smart. Nära. Med tjänsten Healfy kan människor själv ta pulsen på sitt välmående på en plats som fungerar för dom.</p>
        <AnchorLink className="page-link" href="#hur-funkar-det">
          Hur funkar det?
        </AnchorLink>
      </div>
      <div className="col-xs-12 col-md-6">
        <img className="mt-sm-5" src={tabletImage} alt="tablet" />
      </div>
    </div>
  </Section>
)

export default Section1
