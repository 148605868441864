import React from 'react'
import { Section } from '../components/section'

const Section2 = ({ theme = 'dark' }) => (
  <Section theme={theme} legacy>
    <div className="row">
      <div className="col col-md-6">
        <h1>Hälsorummet</h1>
        <p>
          Hälsorummet är perfekt för glesbygd, äldreboenden och andra miljöer
          där tillgång till sjukvården kan vara svår på grund av till exempel
          avstånd.
        </p>
        <p>
          Hälsorummet är utformat så att patienter inte ska behöva åka till en
          vårdcentral för att kolla sin hälsa. I hälsorummet genomför patienten
          själv en digital mätning av bland annat blodtryck, vikt, blodsocker
          och blodvärde.
        </p>
        <p>
          Utrustning för att kunna genomföra mätningarna är oftast permanent
          installerat i rummet som används. Datan skickas sen vidare till en
          vårdcentral eller sjukhus för analys av sjuksköterska eller läkare.
        </p>
        <div className="link-wrapper">
          <a
            className="page-link"
            href="mailto:bjorn.sundqvist@nhiab.com?subject=Demo Hälsorummet"
          >
            Boka demo
          </a>
        </div>
      </div>
      <div className="col col-md-6">
        <h3 className="mt-sm-5">Hantera inskickad data från Healfy</h3>
        <p>
          När en användare har skickat in datan från hälsorummet integreras den
          med vårdgivarnas redan befintliga verksamhetssystem, till exempel
          journalsystem.
        </p>
        <p>
          Detta sker i Healfys vårdgivargränssnitt, snabbt och enkelt via
          webben.
        </p>
      </div>
    </div>
  </Section>
)

export default Section2
