import React from 'react'
import { Button } from '../components/button'
import { Section } from '../components/section'

const Section3 = ({ theme = 'light' }) => (
  <Section theme={theme} legacy>
    <div className="row">
      <div className="col col-md-6">
        <h1>Företagshälsovård</h1>
        <p>
          Healfy är perfekt för leverantörer av företagshälsovård som vill
          erbjuda en modern och proaktiv lösning för sina kunder.
        </p>
        <p>
          Healfy företagshälsovård gör det möjligt för anställda att själva
          genomföra en digital mätning av bland annat blodtryck, vikt,
          blodsocker och blodvärde. Mätningen går också att anpassa efter
          specifika omständigheter på arbetsplatsen, till exempel kan tunga lyft
          vara en utmaning och psykisk ohälsa en annan.
        </p>
        <p>
          Utrustningen som behövs för mätningar kan vara permanent installerad
          på arbetsplatsen, eller vara portabel där vårdgivaren kommer ut till
          kunden under en bestämd tidsperiod.
        </p>
        <div className="link-wrapper">
          <a
            className="page-link"
            href="mailto:bjorn.sundqvist@nhiab.com?subject=Demo Företagshälsovård"
          >
            Boka demo
          </a>
        </div>
      </div>
    </div>
  </Section>
)

export default Section3
